import React from 'react';
import { Typography, Box, List, ListItem, ListItemText } from '@mui/material';

const WebhookX = () => {
  return (
    <Box sx={{ mt: 4 }}>
      <Typography variant="h2" component="h1" gutterBottom>
        WebhookX Commands
      </Typography>
      <Typography variant="body1" gutterBottom>
        WebhookX is a powerful bot for managing webhooks, allowing you to automate and streamline notifications and integrations.
      </Typography>
      <Typography variant="h4" component="h2" gutterBottom>
        Commands
      </Typography>
      <List>
        <ListItem>
          <ListItemText primary="!addwebhook - Add a new webhook" />
        </ListItem>
        <ListItem>
          <ListItemText primary="!removewebhook - Remove an existing webhook" />
        </ListItem>
        <ListItem>
          <ListItemText primary="!listwebhooks - List all configured webhooks" />
        </ListItem>
      </List>
      <Typography variant="h4" component="h2" gutterBottom>
        Get WebhookX
      </Typography>
      <Typography variant="body1">
        You can add WebhookX to your server by [insert instructions or link here].
      </Typography>
    </Box>
  );
};

export default WebhookX;
