import React from 'react';
import { Typography, Box, Card, CardContent, CardMedia, Grid, Button } from '@mui/material';
import { Link } from 'react-router-dom';
import styled from '@emotion/styled';

const Game1Image = `${process.env.PUBLIC_URL}/golfx.png`;
const Game2Image = `${process.env.PUBLIC_URL}/dualball.png`;
const Game3Image = `${process.env.PUBLIC_URL}/untitled.png`;

const GameCard = styled(Card)`
  margin: 20px;
`;

const GameMedia = styled(CardMedia)`
  height: 300px; // Adjust the height as needed
`;

const StyledButton = styled(Button)`
  margin-top: 10px;
`;

const Games = () => {
  return (
    <Box sx={{ mt: 4 }}>
      <Typography variant="h2" component="h1" gutterBottom>
        Games
      </Typography>
      <Grid container spacing={3}>
        <Grid item xs={12} md={4}>
          <GameCard>
            <GameMedia
              component="img"
              image={Game1Image}
              alt="GolfX"
            />
            <CardContent>
              <Typography gutterBottom variant="h5" component="div">
                GolfX
              </Typography>
              <Typography variant="body2" color="text.secondary" paragraph>
                An exciting golf simulation game with modern and sleek design.
              </Typography>
              <StyledButton variant="contained" color="primary" component={Link} to="/golfx">
                View Details
              </StyledButton>
            </CardContent>
          </GameCard>
        </Grid>
        <Grid item xs={12} md={4}>
          <GameCard>
            <GameMedia
              component="img"
              image={Game2Image}
              alt="DualBall"
            />
            <CardContent>
              <Typography gutterBottom variant="h5" component="div">
                DualBall
              </Typography>
              <Typography variant="body2" color="text.secondary" paragraph>
                A puzzle game where you control two balls to solve challenging levels.
              </Typography>
              <StyledButton variant="contained" color="primary" component={Link} to="/dualball">
                View Details
              </StyledButton>
            </CardContent>
          </GameCard>
        </Grid>
        <Grid item xs={12} md={4}>
          <GameCard>
            <GameMedia
              component="img"
              image={Game3Image}
              alt="Untitled Game"
            />
            <CardContent>
              <Typography gutterBottom variant="h5" component="div">
                Untitled Game in Development
              </Typography>
              <Typography variant="body2" color="text.secondary" paragraph>
                A game currently in development with futuristic and innovative design.
              </Typography>
              <StyledButton variant="contained" color="primary" component={Link} to="/untitled-game">
                View Details
              </StyledButton>
            </CardContent>
          </GameCard>
        </Grid>
      </Grid>
    </Box>
  );
};

export default Games;
