import React from 'react';
import { Typography, Box, List, ListItem, ListItemText } from '@mui/material';

const RssMapperPremium = () => {
  return (
    <Box sx={{ mt: 4 }}>
      <Typography variant="h2" component="h1" gutterBottom>
        RssMapper Premium
      </Typography>
      <Typography variant="body1" paragraph>
        RssMapper Premium offers enhanced features for your RSS feed management:
      </Typography>
      <List>
        <ListItem>
          <ListItemText primary="Hourly feed updates" />
        </ListItem>
        <ListItem>
          <ListItemText primary="Priority support" />
        </ListItem>
        <ListItem>
          <ListItemText primary="Additional customization options for embed messages" />
        </ListItem>
        <ListItem>
          <ListItemText 
            primary={
              <>
                <span style={{ fontWeight: 'bold', color: '#7289DA' }}>@RssMapper Supporter</span> role on the Discord support server
              </>
            }
          />
        </ListItem>
      </List>
      <Typography variant="body1" paragraph>
        With RssMapper Premium, you can ensure that your feeds are always up-to-date and have access to the best support and customization features.
      </Typography>
      <Typography variant="h4" component="h2" gutterBottom>
        Pricing
      </Typography>
      <Typography variant="body1" paragraph>
        RssMapper Premium is available for $5 per month. You can subscribe using PayPal or a credit card.
      </Typography>
      <Typography variant="h4" component="h2" gutterBottom>
        How to Subscribe
      </Typography>
      <Typography variant="body1" paragraph>
        To subscribe to RssMapper Premium, follow these steps:
      </Typography>
      <List>
        <ListItem>
          <ListItemText primary="1. Go to the RssMapper Premium subscription page." />
        </ListItem>
        <ListItem>
          <ListItemText primary="2. Choose your payment method (PayPal or credit card)." />
        </ListItem>
        <ListItem>
          <ListItemText primary="3. Complete the payment process." />
        </ListItem>
      </List>
      <Typography variant="body1" paragraph>
        Once you have subscribed, your account will be upgraded to RssMapper Premium, and you can start enjoying the enhanced features immediately.
      </Typography>
    </Box>
  );
};

export default RssMapperPremium;
