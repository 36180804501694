import { createGlobalStyle } from 'styled-components';

const GlobalStyle = createGlobalStyle`
  body {
    font-family: 'Roboto', sans-serif;
    color: #333;
  }

  h1, h2, h3, h4, h5, h6 {
    color: #1976d2;
    font-weight: 700;
  }

  p, li {
    line-height: 1.6;
  }

  a {
    color: #1976d2;
    text-decoration: none;
  }

  a:hover {
    text-decoration: underline;
  }

  .icon-padding {
    margin-right: 8px;
  }

  footer a {
    color: white;
    margin: 0 10px;
  }
`;

export default GlobalStyle;
