import React from 'react';
import { Typography, Box, Card, CardContent, CardMedia } from '@mui/material';

const GolfXImage = `${process.env.PUBLIC_URL}/Create_a_placeholder_image_for_a_game_called_GolfX.png`;

const GolfX = () => {
  return (
    <Box sx={{ mt: 4 }}>
      <Card>
        <CardMedia
          component="img"
          height="300"
          image={GolfXImage}
          alt="GolfX"
        />
        <CardContent>
          <Typography variant="h2" component="h1" gutterBottom>
            GolfX
          </Typography>
          <Typography variant="body1" paragraph>
            GolfX is an exciting golf simulation game with modern and sleek design. Experience the thrill of the golf course with realistic physics and stunning graphics.
          </Typography>
          <Typography variant="h4" component="h2" gutterBottom>
            Features
          </Typography>
          <Typography variant="body1" paragraph>
            - Realistic golf physics
            <br />
            - Multiple courses
            <br />
            - Customizable characters
          </Typography>
        </CardContent>
      </Card>
    </Box>
  );
};

export default GolfX;
