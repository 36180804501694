import React from 'react';
import { Typography, Box, Card, CardContent, CardMedia } from '@mui/material';

const UntitledGameImage = `${process.env.PUBLIC_URL}/Create_a_placeholder_image_for_an_untitled_game_in.png`;

const UntitledGame = () => {
  return (
    <Box sx={{ mt: 4 }}>
      <Card>
        <CardMedia
          component="img"
          height="300"
          image={UntitledGameImage}
          alt="Untitled Game"
        />
        <CardContent>
          <Typography variant="h2" component="h1" gutterBottom>
            Untitled Game in Development
          </Typography>
          <Typography variant="body1" paragraph>
            This is a game currently in development. Stay tuned for more details and exciting updates about this upcoming game.
          </Typography>
          <Typography variant="h4" component="h2" gutterBottom>
            Features
          </Typography>
          <Typography variant="body1" paragraph>
            - Innovative gameplay mechanics
            <br />
            - Futuristic design
            <br />
            - Engaging storyline
          </Typography>
        </CardContent>
      </Card>
    </Box>
  );
};

export default UntitledGame;
