import React from 'react';
import { Typography, Box, Card, CardContent, CardMedia } from '@mui/material';

const DualBallImage = `${process.env.PUBLIC_URL}/Create_a_placeholder_image_for_a_game_called_DualB.png`;

const DualBall = () => {
  return (
    <Box sx={{ mt: 4 }}>
      <Card>
        <CardMedia
          component="img"
          height="300"
          image={DualBallImage}
          alt="DualBall"
        />
        <CardContent>
          <Typography variant="h2" component="h1" gutterBottom>
            DualBall
          </Typography>
          <Typography variant="body1" paragraph>
            DualBall is an innovative puzzle game where you control two balls simultaneously to solve challenging levels. Test your strategy and coordination skills.
          </Typography>
          <Typography variant="h4" component="h2" gutterBottom>
            Features
          </Typography>
          <Typography variant="body1" paragraph>
            - Unique dual-control gameplay
            <br />
            - Hundreds of levels
            <br />
            - Various obstacles and challenges
          </Typography>
        </CardContent>
      </Card>
    </Box>
  );
};

export default DualBall;
