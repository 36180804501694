import React from 'react';
import { Typography, Box, List, ListItem, ListItemText } from '@mui/material';

const DxMon = () => {
  return (
    <Box sx={{ mt: 4 }}>
      <Typography variant="h2" component="h1" gutterBottom>
        3dxMon Commands
      </Typography>
      <Typography variant="body1" gutterBottom>
        3dxMon is a monitoring bot that keeps track of ABC metrics and provides real-time notifications.
      </Typography>
      <Typography variant="h4" component="h2" gutterBottom>
        Commands
      </Typography>
      <List>
        <ListItem>
          <ListItemText primary="!monitor - Start monitoring metrics" />
        </ListItem>
        <ListItem>
          <ListItemText primary="!alerts - Show current alerts" />
        </ListItem>
        <ListItem>
          <ListItemText primary="!settings - Configure monitoring settings" />
        </ListItem>
      </List>
      <Typography variant="h4" component="h2" gutterBottom>
        Get 3dxMon
      </Typography>
      <Typography variant="body1">
        You can add 3dxMon to your server by [insert instructions or link here].
      </Typography>
    </Box>
  );
};

export default DxMon;
