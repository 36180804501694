import React from 'react';
import { Typography, Box } from '@mui/material';

const PrivacyPolicy = () => {
  return (
    <Box sx={{ mt: 4 }}>
      <Typography variant="h2" component="h1" gutterBottom>
        Privacy Policy
      </Typography>
      <Typography variant="body1" paragraph>
        Your privacy is important to us. This privacy policy explains how we collect, use, and protect your information:
      </Typography>
      
      <Typography variant="h4" component="h2" gutterBottom>
        1. Information Collection
      </Typography>
      <Typography variant="body1" paragraph>
        We collect information that you provide to us directly, such as when you create an account or contact us for support. We also collect information automatically as you use our services, such as your IP address and usage data.
      </Typography>
      
      <Typography variant="h4" component="h2" gutterBottom>
        2. Use of Information
      </Typography>
      <Typography variant="body1" paragraph>
        We use the information we collect to provide, maintain, and improve our services, to communicate with you, and to protect our users. We may also use the information for research and analytics purposes.
      </Typography>
      
      <Typography variant="h4" component="h2" gutterBottom>
        3. Information Sharing
      </Typography>
      <Typography variant="body1" paragraph>
        We do not share your personal information with third parties except as necessary to provide our services, comply with the law, or protect our rights. We may share aggregated or anonymized information that does not directly identify you.
      </Typography>
      
      <Typography variant="h4" component="h2" gutterBottom>
        4. Data Security
      </Typography>
      <Typography variant="body1" paragraph>
        We implement security measures to protect your information from unauthorized access, alteration, disclosure, or destruction. However, no security system is completely secure, and we cannot guarantee the absolute security of your information.
      </Typography>
      
      <Typography variant="h4" component="h2" gutterBottom>
        5. Your Choices
      </Typography>
      <Typography variant="body1" paragraph>
        You can choose not to provide certain information to us, but this may limit your ability to use some features of our services. You can also contact us to update or delete your personal information.
      </Typography>
      
      <Typography variant="h4" component="h2" gutterBottom>
        6. Changes to this Policy
      </Typography>
      <Typography variant="body1" paragraph>
        We may update this privacy policy from time to time. We will notify you of any changes by posting the new policy on this page. Your continued use of our services after any changes indicates your acceptance of the new policy.
      </Typography>
      
      <Typography variant="h4" component="h2" gutterBottom>
        7. Contact Us
      </Typography>
      <Typography variant="body1" paragraph>
        If you have any questions about this privacy policy, please contact us at support@myportfolio.com.
      </Typography>
    </Box>
  );
};

export default PrivacyPolicy;
