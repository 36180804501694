import React from 'react';
import { Routes, Route, Link } from 'react-router-dom';
import Home from './pages/Home';
import DiscordBots from './pages/DiscordBots';
import WindowsApps from './pages/WindowsApps';
import Games from './pages/Games';
import GolfX from './pages/GolfX';
import DualBall from './pages/DualBall';
import UntitledGame from './pages/UntitledGame';
import About from './pages/About';
import Contact from './pages/Contact';
import PingThing from './pages/PingThing';
import DxMon from './pages/DxMon';
import MazeGen from './pages/MazeGen';
import MazeGenPremium from './pages/MazeGenPremium';
import BossKey from './pages/BossKey';
import Cleanup from './pages/Cleanup';
import WorldTools from './pages/3dxWorldTools';
import DiceThing from './pages/DiceThing';
import WebhookX from './pages/WebhookX';
import RssMapper from './pages/RssMapper';
import RssMapperPremium from './pages/RssMapperPremium';
import TermsOfService from './pages/TermsOfService';
import PrivacyPolicy from './pages/PrivacyPolicy';
import { AppBar, Toolbar, Typography, Button, Container, Box } from '@mui/material';
import styled from '@emotion/styled';
import GlobalStyle from './globalStyles';
import { FaHome, FaDiscord, FaWindows, FaGamepad, FaInfoCircle, FaEnvelope, FaFileContract, FaLock } from 'react-icons/fa';

const StyledLink = styled(Link)`
  color: white;
  text-decoration: none;
  margin: 0 10px;
  display: flex;
  align-items: center;
`;

const Logo = styled.img`
  height: 40px;
  margin-right: 10px;
`;

const Footer = styled.footer`
  background-color: #1976d2;
  color: white;
  padding: 20px 0;
  text-align: center;
  margin-top: 20px;
`;

const App = () => {
  return (
    <div
      style={{
        backgroundImage: `url(${process.env.PUBLIC_URL}/tileable-background-faded.png)`,
        backgroundSize: 'auto',
        backgroundRepeat: 'repeat',
        minHeight: '100vh',
        display: 'flex',
        flexDirection: 'column',
      }}
    >
      <GlobalStyle />
      <AppBar position="static">
        <Toolbar>
          <Logo src={`${process.env.PUBLIC_URL}/logo.png`} alt="Logo" />
          <Typography variant="h6" component="div" sx={{ flexGrow: 1 }}>
            My Portfolio
          </Typography>
          <StyledLink to="/">
            <FaHome className="icon-padding" /> Home
          </StyledLink>
          <StyledLink to="/discord-bots">
            <FaDiscord className="icon-padding" /> Discord Bots
          </StyledLink>
          <StyledLink to="/windows-apps">
            <FaWindows className="icon-padding" /> Windows Apps
          </StyledLink>
          <StyledLink to="/games">
            <FaGamepad className="icon-padding" /> Games
          </StyledLink>
          <StyledLink to="/about">
            <FaInfoCircle className="icon-padding" /> About
          </StyledLink>
          <StyledLink to="/contact">
            <FaEnvelope className="icon-padding" /> Contact
          </StyledLink>
        </Toolbar>
      </AppBar>
      <Container>
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/discord-bots" element={<DiscordBots />} />
          <Route path="/windows-apps" element={<WindowsApps />} />
          <Route path="/games" element={<Games />} />
          <Route path="/golfx" element={<GolfX />} />
          <Route path="/dualball" element={<DualBall />} />
          <Route path="/untitled-game" element={<UntitledGame />} />
          <Route path="/about" element={<About />} />
          <Route path="/contact" element={<Contact />} />
          <Route path="/pingthing" element={<PingThing />} />
          <Route path="/dxmon" element={<DxMon />} />
          <Route path="/mazegen" element={<MazeGen />} />
          <Route path="/mazegen-premium" element={<MazeGenPremium />} />
          <Route path="/bosskey" element={<BossKey />} />
          <Route path="/cleanup" element={<Cleanup />} />
          <Route path="/3dxworldtools" element={<WorldTools />} />
          <Route path="/dicething" element={<DiceThing />} />
          <Route path="/webhookx" element={<WebhookX />} />
          <Route path="/rssmapper" element={<RssMapper />} />
          <Route path="/rssmapper-premium" element={<RssMapperPremium />} />
          <Route path="/terms-of-service" element={<TermsOfService />} />
          <Route path="/privacy-policy" element={<PrivacyPolicy />} />
        </Routes>
      </Container>
      <Footer>
        <Box>
          <StyledLink to="/terms-of-service">
            <FaFileContract className="icon-padding" /> Terms of Service
          </StyledLink>
          <StyledLink to="/privacy-policy">
            <FaLock className="icon-padding" /> Privacy Policy
          </StyledLink>
        </Box>
      </Footer>
    </div>
  );
};

export default App;
