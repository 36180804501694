import React from 'react';
import { Typography, Box, Card, CardContent, CardMedia, Grid } from '@mui/material';

const BossKeyImage = `${process.env.PUBLIC_URL}/BossKey.png`;
const BossKeyScreenshot1 = `${process.env.PUBLIC_URL}/BossKeyMockup.png`;

const BossKey = () => {
  return (
    <Box sx={{ mt: 4 }}>
      <Card>
        <CardMedia
          component="img"
          height="300"
          image={BossKeyImage}
          alt="BossKey"
        />
        <CardContent>
          <Typography variant="h2" component="h1" gutterBottom>
            BossKey
          </Typography>
          <Typography variant="body1" paragraph>
            BossKey is a productivity application that allows you to quickly hide all open windows with a single keystroke. Perfect for when you need to switch to a different task quickly or hide sensitive information from prying eyes.
          </Typography>
          <Typography variant="h4" component="h2" gutterBottom>
            Features
          </Typography>
          <Typography variant="body1" paragraph>
            - Instant window hiding
            <br />
            - Customizable hotkeys
            <br />
            - Lightweight and easy to use
          </Typography>
        </CardContent>
      </Card>
      <Typography variant="h4" component="h2" gutterBottom sx={{ mt: 4 }}>
        Screenshots
      </Typography>
      <Grid container spacing={2}>
        <Grid item xs={12} md={6}>
          <Card>
            <CardMedia
              component="img"
              height="300"
              image={BossKeyScreenshot1}
              alt="BossKey Screenshot 1"
            />
          </Card>
        </Grid>
        {/* Add more screenshots as needed */}
      </Grid>
    </Box>
  );
};

export default BossKey;
