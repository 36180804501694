import React from 'react';
import { Typography, Box, List, ListItem, ListItemText } from '@mui/material';

const DiceThing = () => {
  return (
    <Box sx={{ mt: 4 }}>
      <Typography variant="h2" component="h1" gutterBottom>
        DiceThing Commands
      </Typography>
      <Typography variant="body1" gutterBottom>
        DiceThing is a versatile bot designed to handle all your dice-rolling needs, perfect for tabletop games and more.
      </Typography>
      <Typography variant="h4" component="h2" gutterBottom>
        Commands
      </Typography>
      <List>
        <ListItem>
          <ListItemText primary="!roll - Roll a dice (e.g., !roll 2d6)" />
        </ListItem>
        <ListItem>
          <ListItemText primary="!rollstats - Roll for character stats" />
        </ListItem>
        <ListItem>
          <ListItemText primary="!rollcustom - Roll a custom dice (e.g., !rollcustom 4d10+5)" />
        </ListItem>
      </List>
      <Typography variant="h4" component="h2" gutterBottom>
        Get DiceThing
      </Typography>
      <Typography variant="body1">
        You can add DiceThing to your server by [insert instructions or link here].
      </Typography>
    </Box>
  );
};

export default DiceThing;
