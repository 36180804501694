import React from 'react';
import { Typography, Box, List, ListItem, ListItemText } from '@mui/material';

const MazeGenPremium = () => {
  return (
    <Box sx={{ mt: 4 }}>
      <Typography variant="h2" component="h1" gutterBottom>
        MazeGen Premium
      </Typography>
      <Typography variant="body1" paragraph>
        MazeGen Premium offers enhanced features for your maze generation needs:
      </Typography>
      <List>
        <ListItem>
          <ListItemText primary="Hourly maze generation updates" />
        </ListItem>
        <ListItem>
          <ListItemText primary="Priority support" />
        </ListItem>
        <ListItem>
          <ListItemText primary="Additional customization options for maze generation" />
        </ListItem>
        <ListItem>
          <ListItemText 
            primary={
              <>
                <span style={{ fontWeight: 'bold', color: '#7289DA' }}>@MazeGen Supporter</span> role on the Discord support server
              </>
            }
          />
        </ListItem>
      </List>
      <Typography variant="body1" paragraph>
        With MazeGen Premium, you can ensure that your mazes are always up-to-date and have access to the best support and customization features.
      </Typography>
      <Typography variant="h4" component="h2" gutterBottom>
        Pricing
      </Typography>
      <Typography variant="body1" paragraph>
        MazeGen Premium is available for $5 per month. You can subscribe using PayPal or a credit card.
      </Typography>
      <Typography variant="h4" component="h2" gutterBottom>
        How to Subscribe
      </Typography>
      <Typography variant="body1" paragraph>
        To subscribe to MazeGen Premium, follow these steps:
      </Typography>
      <List>
        <ListItem>
          <ListItemText primary="1. Go to the MazeGen Premium subscription page." />
        </ListItem>
        <ListItem>
          <ListItemText primary="2. Choose your payment method (PayPal or credit card)." />
        </ListItem>
        <ListItem>
          <ListItemText primary="3. Complete the payment process." />
        </ListItem>
      </List>
      <Typography variant="body1" paragraph>
        Once you have subscribed, your account will be upgraded to MazeGen Premium, and you can start enjoying the enhanced features immediately.
      </Typography>
    </Box>
  );
};

export default MazeGenPremium;
