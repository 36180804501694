import React from 'react';
import { Typography, Box, List, ListItem, ListItemText } from '@mui/material';

const RssMapper = () => {
  return (
    <Box sx={{ mt: 4 }}>
      <Typography variant="h2" component="h1" gutterBottom>
        RssMapper Commands
      </Typography>
      <Typography variant="body1" gutterBottom>
        RssMapper processes any RSS feed and maps it into a nicely formatted templated embed message that will be posted in the specified channel.
      </Typography>
      <Typography variant="h4" component="h2" gutterBottom>
        Commands
      </Typography>
      <List>
        <ListItem>
          <ListItemText primary="!addfeed - Add a new RSS feed" />
        </ListItem>
        <ListItem>
          <ListItemText primary="!removefeed - Remove an existing RSS feed" />
        </ListItem>
        <ListItem>
          <ListItemText primary="!listfeeds - List all configured RSS feeds" />
        </ListItem>
      </List>
      <Typography variant="h4" component="h2" gutterBottom>
        Get RssMapper
      </Typography>
      <Typography variant="body1">
        You can add RssMapper to your server by [insert instructions or link here].
      </Typography>
      <Typography variant="h4" component="h2" gutterBottom sx={{ mt: 4 }}>
        Premium Version
      </Typography>
      <Typography variant="body1">
        The premium version of RssMapper updates your feeds once per hour, while the free version updates once per day. For more information on subscribing to the premium version, [insert instructions or link here].
      </Typography>
    </Box>
  );
};

export default RssMapper;
