import React from 'react';
import { Typography, Box } from '@mui/material';

const TermsOfService = () => {
  return (
    <Box sx={{ mt: 4 }}>
      <Typography variant="h2" component="h1" gutterBottom>
        Terms of Service
      </Typography>
      <Typography variant="body1" paragraph>
        Welcome to My Portfolio. By using our services, including Discord bots, Discord support, and Windows applications, you agree to the following terms and conditions:
      </Typography>

      <Typography variant="h4" component="h2" gutterBottom>
        1. Acceptance of Terms
      </Typography>
      <Typography variant="body1" paragraph>
        By accessing and using our services, you accept and agree to be bound by the terms and provisions of this agreement. If you do not agree to these terms, please do not use our services.
      </Typography>

      <Typography variant="h4" component="h2" gutterBottom>
        2. Changes to Terms
      </Typography>
      <Typography variant="body1" paragraph>
        We reserve the right to modify these terms at any time. Any changes will be posted on this page, and your continued use of the service constitutes acceptance of those changes.
      </Typography>

      <Typography variant="h4" component="h2" gutterBottom>
        3. Services
      </Typography>
      <Typography variant="h5" component="h3" gutterBottom>
        3.1 Discord Bots
      </Typography>
      <Typography variant="body1" paragraph>
        Our Discord bots are provided to enhance your Discord server experience. By adding our bots to your server, you agree to follow Discord's Terms of Service and Community Guidelines. You also agree not to misuse the bots or use them for any malicious purposes.
      </Typography>
      <Typography variant="h5" component="h3" gutterBottom>
        3.2 Discord Support
      </Typography>
      <Typography variant="body1" paragraph>
        We offer support through our Discord server. By joining our support server, you agree to follow our server rules and Discord's Community Guidelines. We reserve the right to remove you from the server for any behavior that we deem inappropriate.
      </Typography>
      <Typography variant="h5" component="h3" gutterBottom>
        3.3 Windows Applications
      </Typography>
      <Typography variant="body1" paragraph>
        Our Windows applications are provided to help you with various tasks on your computer. You agree not to reverse engineer, decompile, or otherwise attempt to extract the source code of these applications. You also agree not to use these applications for any malicious purposes.
      </Typography>

      <Typography variant="h4" component="h2" gutterBottom>
        4. User Responsibilities
      </Typography>
      <Typography variant="body1" paragraph>
        You agree to use our services only for lawful purposes and in accordance with these terms. You must not use our services to engage in any unlawful or prohibited activity, including but not limited to:
      </Typography>
      <Typography variant="body1" component="ul">
        <li>Harassing, threatening, or defaming any individual or entity.</li>
        <li>Infringing on any intellectual property rights.</li>
        <li>Distributing malware or other malicious software.</li>
        <li>Engaging in any activity that could harm our services or interfere with any other party's use of our services.</li>
      </Typography>

      <Typography variant="h4" component="h2" gutterBottom>
        5. Termination
      </Typography>
      <Typography variant="body1" paragraph>
        We reserve the right to terminate your access to our services at any time, without notice, for conduct that we believe violates these terms or is harmful to other users.
      </Typography>

      <Typography variant="h4" component="h2" gutterBottom>
        6. Limitation of Liability
      </Typography>
      <Typography variant="body1" paragraph>
        To the maximum extent permitted by law, we shall not be liable for any direct, indirect, incidental, special, or consequential damages arising out of or in connection with your use of our services. This includes, but is not limited to, any loss of use, data, or profits, whether in an action of contract, negligence, or other tortious action, arising out of or in connection with the use or performance of our services.
      </Typography>

      <Typography variant="h4" component="h2" gutterBottom>
        7. Indemnification
      </Typography>
      <Typography variant="body1" paragraph>
        You agree to indemnify and hold us harmless from any claims, losses, damages, liabilities, including legal fees, arising out of your use or misuse of our services, your violation of these terms, or your violation of any rights of another.
      </Typography>

      <Typography variant="h4" component="h2" gutterBottom>
        8. Governing Law
      </Typography>
      <Typography variant="body1" paragraph>
        These terms shall be governed by and construed in accordance with the laws of [Your Country/State], without regard to its conflict of law principles.
      </Typography>

      <Typography variant="h4" component="h2" gutterBottom>
        9. Contact Information
      </Typography>
      <Typography variant="body1" paragraph>
        If you have any questions about these Terms of Service, please contact us at support@myportfolio.com.
      </Typography>
    </Box>
  );
};

export default TermsOfService;
