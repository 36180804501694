import React from 'react';
import { Typography, Box, Card, CardContent, CardMedia, Grid, Button } from '@mui/material';
import { Link } from 'react-router-dom';
import styled from '@emotion/styled';

const BossKeyImage = `${process.env.PUBLIC_URL}/bosskey.png`;
const CleanupImage = `${process.env.PUBLIC_URL}/cleanup.png`;
const WorldToolsImage = `${process.env.PUBLIC_URL}/3dxworldtools.png`;

const AppCard = styled(Card)`
  margin: 20px;
`;

const AppMedia = styled(CardMedia)`
  height: 300px; // Adjust the height as needed
`;

const StyledButton = styled(Button)`
  margin-top: 10px;
`;

const WindowsApps = () => {
  return (
    <Box sx={{ mt: 4 }}>
      <Typography variant="h2" component="h1" gutterBottom>
        Windows Applications
      </Typography>
      <Grid container spacing={3}>
        <Grid item xs={12} md={4}>
          <AppCard>
            <AppMedia
              component="img"
              image={BossKeyImage}
              alt="BossKey"
            />
            <CardContent>
              <Typography gutterBottom variant="h5" component="div">
                BossKey
              </Typography>
              <Typography variant="body2" color="text.secondary" paragraph>
                A productivity application to quickly hide all open windows with a single keystroke.
              </Typography>
              <StyledButton variant="contained" color="primary" component={Link} to="/bosskey">
                View Details
              </StyledButton>
            </CardContent>
          </AppCard>
        </Grid>
        <Grid item xs={12} md={4}>
          <AppCard>
            <AppMedia
              component="img"
              image={CleanupImage}
              alt="Cleanup"
            />
            <CardContent>
              <Typography gutterBottom variant="h5" component="div">
                Cleanup
              </Typography>
              <Typography variant="body2" color="text.secondary" paragraph>
                A utility application designed to help you keep your computer clean and organized.
              </Typography>
              <StyledButton variant="contained" color="primary" component={Link} to="/cleanup">
                View Details
              </StyledButton>
            </CardContent>
          </AppCard>
        </Grid>
        <Grid item xs={12} md={4}>
          <AppCard>
            <AppMedia
              component="img"
              image={WorldToolsImage}
              alt="3dxWorldTools"
            />
            <CardContent>
              <Typography gutterBottom variant="h5" component="div">
                3dxWorldTools
              </Typography>
              <Typography variant="body2" color="text.secondary" paragraph>
                A powerful application for 3D modeling and animation.
              </Typography>
              <StyledButton variant="contained" color="primary" component={Link} to="/3dxworldtools">
                View Details
              </StyledButton>
            </CardContent>
          </AppCard>
        </Grid>
      </Grid>
    </Box>
  );
};

export default WindowsApps;
