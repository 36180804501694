import React from 'react';
import { Typography, Box, Card, CardContent, CardMedia, Grid } from '@mui/material';

const CleanupImage = `${process.env.PUBLIC_URL}/Cleanup.png`;
const CleanupScreenshot1 = `${process.env.PUBLIC_URL}/CleanupMockup.png`;

const Cleanup = () => {
  return (
    <Box sx={{ mt: 4 }}>
      <Card>
        <CardMedia
          component="img"
          height="300"
          image={CleanupImage}
          alt="Cleanup"
        />
        <CardContent>
          <Typography variant="h2" component="h1" gutterBottom>
            Cleanup
          </Typography>
          <Typography variant="body1" paragraph>
            Cleanup is a utility application designed to help you keep your computer clean and organized. It scans for unnecessary files and helps you remove them to free up space and improve performance.
          </Typography>
          <Typography variant="h4" component="h2" gutterBottom>
            Features
          </Typography>
          <Typography variant="body1" paragraph>
            - Efficient file scanning
            <br />
            - Easy file removal
            <br />
            - Customizable scan settings
          </Typography>
        </CardContent>
      </Card>
      <Typography variant="h4" component="h2" gutterBottom sx={{ mt: 4 }}>
        Screenshots
      </Typography>
      <Grid container spacing={2}>
        <Grid item xs={12} md={6}>
          <Card>
            <CardMedia
              component="img"
              height="300"
              image={CleanupScreenshot1}
              alt="Cleanup Screenshot 1"
            />
          </Card>
        </Grid>
        {/* Add more screenshots as needed */}
      </Grid>
    </Box>
  );
};

export default Cleanup;
