import React from 'react';
import { Typography, Box, Card, CardContent, CardMedia, Grid } from '@mui/material';

const WorldToolsImage = `${process.env.PUBLIC_URL}/3dxWorldTools.png`;
const WorldToolsScreenshot1 = `${process.env.PUBLIC_URL}/3dxWorldToolsMockup.png`;

const WorldTools = () => {
  return (
    <Box sx={{ mt: 4 }}>
      <Card>
        <CardMedia
          component="img"
          height="300"
          image={WorldToolsImage}
          alt="3dxWorldTools"
        />
        <CardContent>
          <Typography variant="h2" component="h1" gutterBottom>
            3dxWorldTools
          </Typography>
          <Typography variant="body1" paragraph>
            3dxWorldTools is a powerful application designed for 3D modeling and animation. It offers a range of tools to help you create, edit, and animate 3D models with ease.
          </Typography>
          <Typography variant="h4" component="h2" gutterBottom>
            Features
          </Typography>
          <Typography variant="body1" paragraph>
            - Advanced 3D modeling tools
            <br />
            - Comprehensive animation features
            <br />
            - Intuitive user interface
          </Typography>
        </CardContent>
      </Card>
      <Typography variant="h4" component="h2" gutterBottom sx={{ mt: 4 }}>
        Screenshots
      </Typography>
      <Grid container spacing={2}>
        <Grid item xs={12} md={6}>
          <Card>
            <CardMedia
              component="img"
              height="300"
              image={WorldToolsScreenshot1}
              alt="3dxWorldTools Screenshot 1"
            />
          </Card>
        </Grid>
        {/* Add more screenshots as needed */}
      </Grid>
    </Box>
  );
};

export default WorldTools;
