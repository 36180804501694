import React from 'react';
import { Typography, Box, List, ListItem, ListItemText } from '@mui/material';

const MazeGen = () => {
  return (
    <Box sx={{ mt: 4 }}>
      <Typography variant="h2" component="h1" gutterBottom>
        MazeGen Commands
      </Typography>
      <Typography variant="body1" gutterBottom>
        MazeGen generates complex mazes and challenges for your server members to solve and enjoy.
      </Typography>
      <Typography variant="h4" component="h2" gutterBottom>
        Commands
      </Typography>
      <List>
        <ListItem>
          <ListItemText primary="!generate - Generate a new maze" />
        </ListItem>
        <ListItem>
          <ListItemText primary="!solve - Solve the current maze" />
        </ListItem>
        <ListItem>
          <ListItemText primary="!leaderboard - Show the maze-solving leaderboard" />
        </ListItem>
      </List>
      <Typography variant="h4" component="h2" gutterBottom>
        Get MazeGen
      </Typography>
      <Typography variant="body1">
        You can add MazeGen to your server by [insert instructions or link here].
      </Typography>
      <Typography variant="h4" component="h2" gutterBottom>
        Upgrade to MazeGen Premium
      </Typography>
      <Typography variant="body1">
        Upgrade to the premium version of MazeGen to unlock additional features such as [list premium features here]. [Insert instructions or link for upgrading].
      </Typography>
    </Box>
  );
};

export default MazeGen;
