import React from 'react';
import { Typography, Box, List, ListItem, ListItemText } from '@mui/material';

const PingThing = () => {
  return (
    <Box sx={{ mt: 4 }}>
      <Typography variant="h2" component="h1" gutterBottom>
        PingThing Commands
      </Typography>
      <Typography variant="body1" gutterBottom>
        PingThing is a bot designed to help you with XYZ tasks. It is highly configurable and easy to use.
      </Typography>
      <Typography variant="h4" component="h2" gutterBottom>
        Commands
      </Typography>
      <List>
        <ListItem>
          <ListItemText primary="!ping - Check the bot's responsiveness" />
        </ListItem>
        <ListItem>
          <ListItemText primary="!config - Configure the bot settings" />
        </ListItem>
        <ListItem>
          <ListItemText primary="!status - Get the current status" />
        </ListItem>
      </List>
      <Typography variant="h4" component="h2" gutterBottom>
        Get PingThing
      </Typography>
      <Typography variant="body1">
        You can add PingThing to your server by [insert instructions or link here].
      </Typography>
    </Box>
  );
};

export default PingThing;
