import React from 'react';
import { Typography, Box, Card, CardContent, CardMedia, Grid, Button } from '@mui/material';
import { Link } from 'react-router-dom';
import styled from '@emotion/styled';

const PingThingImage = `${process.env.PUBLIC_URL}/PingThingLogo.png`;
const DxMonImage = `${process.env.PUBLIC_URL}/3dxMonLogo.png`;
const MazeGenImage = `${process.env.PUBLIC_URL}/MazeGenLogo.png`;
const DiceThingImage = `${process.env.PUBLIC_URL}/DiceThingLogo.png`;
const WebhookXImage = `${process.env.PUBLIC_URL}/WebhookXLogo.png`;
const RssMapperImage = `${process.env.PUBLIC_URL}/RssMapperLogo.png`;

const BotCard = styled(Card)`
  margin: 20px;
`;

const StyledButton = styled(Button)`
  margin-top: 10px;
`;

const DiscordBots = () => {
  return (
    <Box sx={{ mt: 4 }}>
      <Typography variant="h2" component="h1" gutterBottom>
        Discord Bots
      </Typography>
      <Grid container spacing={3}>
        <Grid item xs={12} md={4}>
          <BotCard>
            <CardMedia
              component="img"
              height="140"
              image={PingThingImage}
              alt="PingThing"
            />
            <CardContent>
              <Typography gutterBottom variant="h5" component="div">
                PingThing
              </Typography>
              <Typography variant="body2" color="text.secondary" paragraph>
                PingThing is a bot designed to help you with XYZ tasks. It is highly configurable and easy to use.
              </Typography>
              <StyledButton variant="contained" color="primary" component={Link} to="/pingthing">
                View Commands
              </StyledButton>
            </CardContent>
          </BotCard>
        </Grid>
        <Grid item xs={12} md={4}>
          <BotCard>
            <CardMedia
              component="img"
              height="140"
              image={DxMonImage}
              alt="3dxMon"
            />
            <CardContent>
              <Typography gutterBottom variant="h5" component="div">
                3dxMon
              </Typography>
              <Typography variant="body2" color="text.secondary" paragraph>
                3dxMon is a monitoring bot that keeps track of ABC metrics and provides real-time notifications.
              </Typography>
              <StyledButton variant="contained" color="primary" component={Link} to="/dxmon">
                View Commands
              </StyledButton>
            </CardContent>
          </BotCard>
        </Grid>
        <Grid item xs={12} md={4}>
          <BotCard>
            <CardMedia
              component="img"
              height="140"
              image={MazeGenImage}
              alt="MazeGen"
            />
            <CardContent>
              <Typography gutterBottom variant="h5" component="div">
                MazeGen
              </Typography>
              <Typography variant="body2" color="text.secondary" paragraph>
                MazeGen generates complex mazes and challenges for your server members to solve and enjoy.
              </Typography>
              <StyledButton variant="contained" color="primary" component={Link} to="/mazegen">
                View Commands
              </StyledButton>
              <StyledButton variant="outlined" color="secondary" component={Link} to="/mazegen-premium" style={{ marginTop: '10px' }}>
                Premium Version
              </StyledButton>
            </CardContent>
          </BotCard>
        </Grid>
        <Grid item xs={12} md={4}>
          <BotCard>
            <CardMedia
              component="img"
              height="140"
              image={DiceThingImage}
              alt="DiceThing"
            />
            <CardContent>
              <Typography gutterBottom variant="h5" component="div">
                DiceThing
              </Typography>
              <Typography variant="body2" color="text.secondary" paragraph>
                DiceThing is a versatile bot designed to handle all your dice-rolling needs.
              </Typography>
              <StyledButton variant="contained" color="primary" component={Link} to="/dicething">
                View Commands
              </StyledButton>
            </CardContent>
          </BotCard>
        </Grid>
        <Grid item xs={12} md={4}>
          <BotCard>
            <CardMedia
              component="img"
              height="140"
              image={WebhookXImage}
              alt="WebhookX"
            />
            <CardContent>
              <Typography gutterBottom variant="h5" component="div">
                WebhookX
              </Typography>
              <Typography variant="body2" color="text.secondary" paragraph>
                WebhookX is a powerful bot for managing webhooks.
              </Typography>
              <StyledButton variant="contained" color="primary" component={Link} to="/webhookx">
                View Commands
              </StyledButton>
            </CardContent>
          </BotCard>
        </Grid>
        <Grid item xs={12} md={4}>
          <BotCard>
            <CardMedia
              component="img"
              height="140"
              image={RssMapperImage}
              alt="RssMapper"
            />
            <CardContent>
              <Typography gutterBottom variant="h5" component="div">
                RssMapper
              </Typography>
              <Typography variant="body2" color="text.secondary" paragraph>
                RssMapper processes any RSS feed and maps it into a nicely formatted templated embed message that will be posted in the specified channel.
              </Typography>
              <StyledButton variant="contained" color="primary" component={Link} to="/rssmapper">
                View Commands
              </StyledButton>
              <StyledButton variant="outlined" color="secondary" component={Link} to="/rssmapper-premium" style={{ marginTop: '10px' }}>
                Premium Version
              </StyledButton>
            </CardContent>
          </BotCard>
        </Grid>
      </Grid>
    </Box>
  );
};

export default DiscordBots;
